import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import ShowMore from '@tedconf/react-show-more';

import PressCard from '../cards/PressCard';

const PressList = () => {
	const data = useStaticQuery(graphql`
		query PressListQuery {
			allMarkdownRemark(
				filter: { frontmatter: { content_type: { eq: "press-entry" } } }
				sort: { fields: frontmatter___creation_date, order: DESC }
			) {
				nodes {
					frontmatter {
						title
						date
						link
						logo {
							alt
							image
						}
					}
				}
			}
		}
	`);

	const { nodes: pressEntries } = data.allMarkdownRemark;
	return (
		<ShowMore items={pressEntries} by={8}>
			{({ current, onMore }) => (
				<>
					<div className="columns is-multiline">
						{current.map((entry, i) => (
							<div
								className="column is-one-quarter-desktop is-half-tablet is-full-mobile"
								key={`${entry.frontmatter}-${i}`}
							>
								<PressCard data={entry} />
							</div>
						))}
					</div>
					<div className="has-text-centered">
						<button
							className="button load-more"
							disabled={!onMore}
							onClick={() => {
								if (!!onMore) onMore();
							}}
						>
							Load More +
						</button>
					</div>
				</>
			)}
		</ShowMore>
	);
};

export default PressList;
