import React from 'react';
import styled from 'styled-components';

import PreviewCompatibleImage from '../PreviewCompatibleImage';

const StyledCard = styled.div`
	padding: 0 2rem;

	.gatsby-image-wrapper {
		margin-bottom: 2rem;
	}

	a {
		transition: 300ms;

		h2 {
			color: ${(props) => props.theme.colors.primary};
			transition: 300ms;
		}

		&:hover {
			h2 {
				color: ${(props) => props.theme.colors.secondary};
			}
		}
	}

	@media (max-width: 768px) {
		margin-bottom: 3rem;
	}

	@media (min-width: 769px) {
		margin-bottom: 5rem;
	}
`;

const PressCard = ({ data }) => {
	const { frontmatter: entry } = data;
	return (
		<StyledCard className="has-text-centered">
			<a href={entry.link} target="_blank" rel="noopener noreferrer">
				<PreviewCompatibleImage imageInfo={entry.logo} />
				<h2 className="entry-date">{entry.date}</h2>
			</a>
		</StyledCard>
	);
};

export default PressCard;
