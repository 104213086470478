import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import PressList from '../components/lists/PressList';

import RegularPage from '../styles/regularPage';

const StyledArticle = styled(RegularPage)`
	min-height: 60vh;
`;

export const PressPageTemplate = ({ title }) => (
	<StyledArticle>
		<section className="section intro-section">
			<div className="container">
				<div className="columns">
					<div className="column">
						<h1>{title}</h1>
					</div>
				</div>
			</div>
		</section>
		<section className="press-list-section">
			<div className="container">
				<PressList />
			</div>
		</section>
	</StyledArticle>
);

PressPageTemplate.propTypes = {
	title: PropTypes.string,
};

const PressPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout background="light">
			<SEO title={frontmatter.title} />
			<PressPageTemplate title={frontmatter.title} />
		</Layout>
	);
};

PressPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default PressPage;

export const pressPageQuery = graphql`
	query PressPage($id: String!) {
		markdownRemark(id: { eq: $id }) {
			frontmatter {
				title
			}
		}
	}
`;
